<template>
  <div
    class="destination-component base-user-search"
    :class="{ 'destination-component--open': isFocus }"
  >
    <div class="bus__inner">
      <city-search
        :hide-double="hideDouble"
        :placeholder="valText"
        :is-valid="isValid"
        :value="destination"
        @focus="onFocus"
        @input="setDestination"
      />
    </div>

    <div
      role="button"
      class="dt__icon"
      @click="openDestinationModal"
    >
      <esmp-icon
        name="pointer"
      />
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapActions } from 'vuex';
// import ModalMixin from '@/mixins/modal.vue';
import Hub from '@/plugins/event-hub';
import { businessTrip } from '@/constants/businessTrip';
import CitySearch from '../city-search.vue';

export default {
  name: 'DestinationComponent',
  // mixins: [ModalMixin],
  props: {
    placeholder: {
      type: String,
      default: 'Место назначения',
    },
    value: {
      type: Object,
      default: () => null,
    },
    block: {
      type: String,
      default: undefined,
    },
    index: {
      type: [Number, String],
      default: undefined,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    hideDouble: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      destination: null,
      isFocus: false,
    };
  },
  computed: {
    val() {
      if (this.value && this.value.code) {
        return this.value;
      }
      if (!this.value || !this.value.region) return null;

      if (this.value.region.abbrev === businessTrip.DESTINATION.cityAbbrev) {
        return this.value.region;
      }

      if (this.value.area?.abbrev === businessTrip.DESTINATION.cityAbbrev) {
        return this.value.area;
      }

      return isEmpty(this.value.settlement)
        ? this.value.city
        : this.value.settlement;
    },
    valText() {
      if (this.val) return this.val.name;

      return this.placeholder;
    },
  },
  methods: {
    ...mapActions('businessTrip', [
      'setActiveDestination',
      'getRegion',
      'getArea',
      'updateExpensesCity',
      'updateTransit',
    ]),
    onFocus(val) {
      this.isFocus = val;
    },
    openDestinationModal() {
      Hub.$emit('toggle-modal', { modalName: 'destinationModal', newValue: true });
      this.setActiveDestination({
        block: this.block,
        index: this.index,
      });
    },
    async setDestination(citySearchValue) {
      if (
        (!isEmpty(this.val)
          && !isEmpty(citySearchValue)
          && citySearchValue.code === this.val.code)
        || (isEmpty(this.val) && isEmpty(citySearchValue))
      ) {
        this.$emit('input');
        return;
      }

      const value = {
        id: this.value ? this.value.id : null,
        region: null,
        area: null,
        city: null,
        settlement: null,
      };
      if (!isEmpty(citySearchValue)) {
        // если город-регион
        if (citySearchValue.isRegion) {
          value.region = citySearchValue;
        } else if (citySearchValue.code) {
          const regionCode = `${citySearchValue.code.substr(0, 2)}000000000`;
          const areaCode = `${citySearchValue.code.substr(0, 5)}000000`;

          value.region = await this.getRegion(regionCode);

          // если город-район
          if (citySearchValue.code === areaCode) {
            value.area = citySearchValue;
          } else {
            value.city = citySearchValue;
            if (areaCode !== regionCode) {
              value.area = await this.getArea({ regionCode, areaCode });
            }
          }
        }
      }

      if (this.block === businessTrip.DESTINATION.accommodation) {
        // т.к. не переведено в стор, и реактивность потеряна :(
        Hub.$emit('setAccommodationDestination', {
          index: this.index,
          value,
        });
      } else if (
        this.block === businessTrip.DESTINATION.transitStart
        || this.block === businessTrip.DESTINATION.transitEnd
      ) {
        const field = this.block === businessTrip.DESTINATION.transitStart
          ? 'startPoint'
          : 'endPoint';

        this.updateTransit({
          index: this.index,
          field,
          value,
        });
      } else if (this.block === businessTrip.DESTINATION.expenses) {
        this.updateExpensesCity({
          index: this.index,
          city: value,
        });
      }

      this.$emit('input', value);
    },
  },
  watch: {
    value() {
      // eslint-disable-next-line prefer-object-spread
      this.destination = Object.assign({}, this.val);
    },
  },
  components: {
    CitySearch,
  },
  mounted() {
    // eslint-disable-next-line prefer-object-spread
    if (this.val) this.destination = Object.assign({}, this.val);
  },
};
</script>

<style lang="scss" scoped>
$bg-gray: #f5f5f5;
$gray: #999;
.bus__input {
  font-size: 16px;
  background-color: $bg-gray;
  &::placeholder {
    font-size: 16px;
    color: $gray;
  }
}
.bus__inner {
  flex-basis: calc(100% - 40px);
}
.dt__icon {
  display: flex;
  flex-basis: 30px;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border: 1px solid $color-primary-1-day;
  color: $color-primary-1-day;
  border-radius: 50%;
  background: rgba(16, 24, 40, 0.05);
  ::v-deep .esmp-svg-icon {
    height: 20px;
    width: 20px;
  }
}
.destination-component {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.destination-component--open {
    z-index: 6;
  }

  &:hover,
  input:hover {
    cursor: pointer;
  }
}
</style>
